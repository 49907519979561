import React, { useMemo } from 'react'
import MultiUiBrandContext from './MultiUiBrandContext'
import { UiTheme } from '../../config/constants'

const MultiUiBrandProvider = ({ uiBrand = UiTheme.hpsmart, ...props }) => {
  const state = useMemo(() => {
    return {
      uiBrand
    }
  }, [uiBrand])

  return (
    <MultiUiBrandContext.Provider value={state}>
      {props.children}
    </MultiUiBrandContext.Provider>
  )
}

export default MultiUiBrandProvider
