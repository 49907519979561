import React from 'react'
import useMultiUiBrandContext from '../../contexts/MultiUiBrand/useMultiUiBrandContext'
import { UiTheme } from '../../config/constants'
import { ThemeProvider as VThemeProvider } from '@veneer/theme'
import { extendSemantics } from '@veneer/semantics'
import primitives from '@veneer/primitives'
import { rgba } from '../../utils/helper'

const hpxTokens = {
  manatee0: '#FDFDFF',
  manatee1: '#F5F6FF',
  manatee2: '#EDEEFE',
  manatee3: '#E0E1EE',
  manatee4: '#D1D2E4',
  manatee5: '#B5B7CB',
  manatee6: '#9B9CB1',
  manatee7: '#828398',
  manatee8: '#696B7E',
  manatee9: '#525365',
  manatee10: '#3C3D4B',
  manatee11: '#292A35',
  manatee12: '#191A22'
}

const hpxTheme = extendSemantics({
  color: {
    light: {
      accent: {
        background: {
          active: hpxTokens.manatee2,
          default: hpxTokens.manatee0,
          hover: hpxTokens.manatee1
        },
        base: {
          active: rgba(hpxTokens.manatee7, 0.6),
          default: rgba(hpxTokens.manatee7, 0.2),
          hover: rgba(hpxTokens.manatee7, 0.4)
        },
        foreground: {
          active: primitives.color.gray12,
          default: primitives.color.gray12,
          hover: primitives.color.gray12
        }
      },
      background: {
        container: {
          active: hpxTokens.manatee2,
          hover: hpxTokens.manatee1,
          default: primitives.color.white
        },
        default: hpxTokens.manatee3,
        disabled: hpxTokens.manatee2,
        medium: hpxTokens.manatee1
      },
      caution: {
        base: {
          active: primitives.color.gold1,
          hover: primitives.color.gold2
        }
      },
      effects: {
        dropShadow: rgba(hpxTokens.manatee8, 0.2),
        glasswall: rgba(hpxTokens.manatee7, 0.6),
        highlight: {
          active: rgba(primitives.color.royalBlue3, 0.6),
          default: rgba(primitives.color.royalBlue5, 0.1),
          hover: rgba(primitives.color.royalBlue4, 0.3)
        }
      },
      focus: {
        default: primitives.color.royalBlue9
      },
      foreground: {
        default: hpxTokens.manatee12,
        disabled: hpxTokens.manatee5,
        light: hpxTokens.manatee8,
        medium: hpxTokens.manatee9
      },
      help: {
        background: {
          active: primitives.color.mediumOrchid2,
          default: primitives.color.mediumOrchid0,
          hover: primitives.color.mediumOrchid1
        },
        base: {
          active: primitives.color.mediumOrchid9,
          default: primitives.color.mediumOrchid7,
          hover: primitives.color.mediumOrchid8
        }
      },
      neutral: {
        background: {
          active: rgba(hpxTokens.manatee7, 0.2),
          default: hpxTokens.manatee1,
          hover: rgba(hpxTokens.manatee7, 0.1)
        },
        base: {
          active: hpxTokens.manatee9,
          default: hpxTokens.manatee7,
          hover: hpxTokens.manatee8
        }
      },
      primary: {
        background: {
          active: primitives.color.royalBlue2,
          default: primitives.color.royalBlue0,
          hover: primitives.color.royalBlue1
        },
        base: {
          active: primitives.color.royalBlue9,
          default: primitives.color.royalBlue7,
          hover: primitives.color.royalBlue8
        }
      },
      spotlight: {
        background: {
          default: hpxTokens.manatee11
        },
        foreground: {
          active: hpxTokens.manatee11,
          default: hpxTokens.manatee11,
          hover: hpxTokens.manatee11
        }
      },
      stroke: {
        default: hpxTokens.manatee2
      }
    },
    dark: {
      accent: {
        background: {
          active: hpxTokens.manatee9,
          default: hpxTokens.manatee12,
          hover: hpxTokens.manatee10
        },
        base: {
          active: rgba(hpxTokens.manatee9, 0.6),
          default: rgba(hpxTokens.manatee9, 0.2),
          hover: rgba(hpxTokens.manatee9, 0.4)
        },
        foreground: {
          active: primitives.color.white,
          default: primitives.color.white,
          hover: primitives.color.white
        }
      },
      background: {
        container: {
          active: hpxTokens.manatee9,
          default: hpxTokens.manatee11,
          hover: hpxTokens.manatee10
        },
        default: hpxTokens.manatee12,
        disabled: rgba(hpxTokens.manatee8, 0.1),
        medium: hpxTokens.manatee10
      },
      effects: {
        glasswall: rgba(hpxTokens.manatee7, 0.6),
        highlight: {
          active: rgba(primitives.color.royalBlue9, 0.6),
          default: rgba(primitives.color.royalBlue7, 0.1),
          hover: rgba(primitives.color.royalBlue8, 0.3)
        }
      },
      focus: {
        default: primitives.color.royalBlue9,
        inverse: hpxTokens.manatee11
      },
      foreground: {
        default: hpxTokens.manatee0,
        disabled: hpxTokens.manatee8,
        light: hpxTokens.manatee5,
        medium: hpxTokens.manatee4
      },
      help: {
        background: {
          active: primitives.color.mediumOrchid10,
          default: primitives.color.mediumOrchid12,
          hover: primitives.color.mediumOrchid11
        },
        base: {
          active: primitives.color.mediumOrchid3,
          default: primitives.color.mediumOrchid5,
          hover: primitives.color.mediumOrchid4
        }
      },
      neutral: {
        background: {
          active: rgba(hpxTokens.manatee6, 0.2),
          default: hpxTokens.manatee10,
          hover: rgba(hpxTokens.manatee6, 0.1)
        },
        base: {
          active: hpxTokens.manatee4,
          default: hpxTokens.manatee6,
          hover: hpxTokens.manatee5
        },
        foreground: {
          active: hpxTokens.manatee12,
          default: hpxTokens.manatee12,
          hover: hpxTokens.manatee12
        }
      },
      primary: {
        background: {
          active: rgba(hpxTokens.manatee2, 0.1),
          default: hpxTokens.manatee12,
          hover: rgba(hpxTokens.manatee1, 0.1)
        },
        base: {
          active: primitives.color.royalBlue3,
          default: primitives.color.royalBlue5,
          hover: primitives.color.royalBlue4
        },
        foreground: {
          active: hpxTokens.manatee12,
          default: hpxTokens.manatee12,
          hover: hpxTokens.manatee12
        }
      },
      spotlight: {
        base: {
          default: hpxTokens.manatee11
        }
      },
      stroke: {
        default: hpxTokens.manatee9
      }
    }
  }
})

export const getThemeProviderConfig = (uiBrand = UiTheme.hpsmart) => {
  const config = {
    customSemantics: undefined,
    mode: 'light',
    shape: 'round',
    density: 'standard',
    inputBackground: undefined
  }

  if (uiBrand === UiTheme.hpx) {
    config.customSemantics = hpxTheme
    config.shape = 'pill'
  }

  return config
}

const ThemeProvider = ({ children }) => {
  const { uiBrand } = useMultiUiBrandContext()
  const props = getThemeProviderConfig(uiBrand)

  return <VThemeProvider {...props}>{children}</VThemeProvider>
}

export default ThemeProvider
