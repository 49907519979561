import React from 'react'
import useMultiUiBrandContext from '../../contexts/MultiUiBrand/useMultiUiBrandContext'
import { UiTheme } from '../../config/constants'
import classes from './styles.module.scss'
import HpxBackground from './hpx-background.png'
import Card from '@veneer/core/dist/esm/scripts/card/card'

const SetupAndOnboardingViewContainer = ({ children }) => {
  const { uiBrand } = useMultiUiBrandContext()

  if (uiBrand === UiTheme.hpx) {
    return (
      <div
        data-testid="setup-and-onboarding-view-container-hpx"
        className={classes['hpx-view-container']}
      >
        <img
          className={classes['img']}
          alt="HPX"
          src={HpxBackground}
          loading="lazy"
        />
        <Card
          className={classes['card']}
          content={<div className={classes['content']}>{children}</div>}
        />
      </div>
    )
  }

  return (
    <div data-testid="setup-and-onboarding-view-container-hpsmart">
      {children}
    </div>
  )
}

export default SetupAndOnboardingViewContainer
